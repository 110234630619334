<template>
    <el-form-item label="地址信息">
        <el-row :gutter="0">
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <el-select v-model="addList.province" placeholder="请选择省" class="address_select" @change="getCityData(addList.province,true)" size="mini">
                        <el-option v-for="item in province" :key="item.adcode" :label="item.name" :value="item.adcode">
                        </el-option>
                    </el-select>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <el-select v-model="addList.city" placeholder="请选择市" class="address_select" @change="getCityAreaData(addList.city,true)" size="mini">
                        <el-option v-for="item in city" :key="item.adcode" :label="item.name" :value="item.adcode">
                        </el-option>
                    </el-select>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="grid-content bg-purple">
                    <el-select v-model="addList.area" placeholder="请选择区" class="address_select" @change="postData()" size="mini">
                        <el-option v-for="item in region" :key="item.adcode" :label="item.name" :value="item.adcode">
                        </el-option>
                    </el-select>
                </div>
            </el-col>
        </el-row>
    </el-form-item>
</template>
<script>
    import axios from 'axios'
    export default {
        name: "cityLinkageReg",
        data() {
            return {
                province: [],
                city: [],
                region: [],
            };
        },
        props: ["addList"],//从父组件接受的数据
        methods: {
            // 获取省
            getProvinceData() {
                const data = new URLSearchParams();
                data.append('adcodeParent', 100000);
                data.append('level', 1);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/pm/region/getRegions`,
                    data:data
                }).then(res=> {
                    this.province = res.data;
                });
            },
            // 点击地区获取市
            getCityData(id, is) {
                if (is) {
                    //判断是否点击上一级
                    this.addList.area = "";
                    this.addList.city = "";
                }
                const data = new URLSearchParams();
                data.append('adcodeParent', id);
                data.append('level', 2);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/pm/region/getRegions`,
                    data:data
                }).then(res=> {
                    this.city = res.data;
                });
                this.postData();
            },
            // 点击城市获取区
            getCityAreaData(id, is) {
                if (is) {
                    //判断是否点击上一级
                    this.addList.area = "";
                }
                const data = new URLSearchParams();
                data.append('adcodeParent', id);
                data.append('level', 3);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/pm/region/getRegions`,
                    data:data
                }).then(res=> {
                    this.region = res.data;
                    this.postData();
                });
            },
            // 向父组件传值
            postData() {
                let _this = this;
                let province = this.province;
                let city = this.city;
                let region = this.region;
                setTimeout(function() {
                    _this.$emit("addlist", _this.addList);
                }, 10);
            }
        },
        mounted() {
            this.$nextTick(function () {
                // Code that will run only after the
                // entire view has been rendered
                this.getProvinceData();
            })
        },
        watch: {
            //监听父组件传值的变化
            addList: {
                handler: function(val, oldval) {
                    this.getCityData(val.province, false);
                    this.getCityAreaData(val.city, false);
                },
                immediate: true, //关键
                deep: true
            }
        }
    };
</script>
